.react-grid-Row {
  height: 20px;
}

.react-grid-Main {
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.maximus-column {
  font-size: 15px;
  text-align: left !important;
}

input.form-control.input-sm {
  width: 80% !important;
  font-size: 15px !important;
}

.react-grid-HeaderCell {
  font-size: 15px;
  overflow: hidden !important;
  height: 30px !important;
  padding: 8px 0 0 0;
  border: 0;
  text-align: left;
}

.react-grid-HeaderCell > div {
  width: 80%;
}

.react-grid-Cell {
  text-align: left;
}

.maximus-image {
  height: 100px;
  background-repeat: no-repeat;
  z-index: -10;
}

.Pagination-pagination-443 {
  float: left !important;
}

.PageSizeSelector-pageSizeSelector-450 {
  float: left !important;
}

@media screen and (max-width: 960px) {
  .devexpress-paggination .Pagination-pagination .Pagination-button {
    display: none;
  }

  .devexpress-paggination .Pagination-pagination .Pagination-activeButton {
    display: inline-block;
  }
}
