html {
  overflow-x: hidden;
  max-width: 100vw;
}

html > * {
  font-family: sans-serif !important;
}

body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  font-family: sans-serif;
  min-height: 100vh;
  background-color: #fdfdfd;
  color: #3a4750;
}

.overflow-hidden,
.overflow-hidden > * {
  overflow: hidden;
  max-height: 100vh !important;
}

.center-cell {
  text-align: center;
}

#root {
  height: 100vh;
}

#appContainer {
  height: 100%;
}

#loginPage {
  height: auto;
  max-width: 1500px;
  margin: auto;
}

.App {
  text-align: center;
}

.responsiveContainer {
  display: inline-flex;
}

#Dark2FontColor {
  color: #3a4750 !important;
}

#Dark2FontColor:focus {
  border-color: yellow !important;
}

#pageContainer {
  height: calc(100% - 50px);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.redirectButton {
  color: #3a4750;
  cursor: pointer;
  text-align: center;
  border-bottom: solid 2px transparent;
  margin-bottom: -2px;
}

.redirectButton:hover {
  border-bottom: solid 2px #3a4750;
}

#submitButton:hover {
  background-color: #282c34;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*** Footer ***/
footer {
  margin-top: 2em;
  padding-top: 1em;
  background: #fff;
  color: #7f7f7f;
  width: 100%;
}

.container {
  margin: 0 auto;
  max-width: 900px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 2em;
}
.text-center {
  text-align: center;
}
.grid-xs-12 {
  margin: 0 auto;
  width: 95%;
  float: left;
}
.grid-sm-3 {
  width: 25%;
  float: left;
}
.grid-xs-4 {
  width: 33.333333%;
  float: left;
}
footer a {
  margin-right: 1em;
  margin-left: 1em;
  font-weight: bold;
  font-size: 12px;
  color: #7f7f7f;
  text-decoration: none;
}
.logos {
  max-width: 200px;
  margin: 0 auto;
}
.logos.leadsCouncil {
  max-width: 185px;
}
.logos.microsoft {
  max-width: 333px;
}

td {
  font-size: 16px !important;
}

th {
  padding-left: 12px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recharts-legend-wrapper {
  width: 100% !important;
  max-width: 100%;
}

.recharts-wrapper {
  max-width: 100%;
}

.recharts-surface {
  max-width: 100%;
  overflow: inherit !important;
}

[class*='Pager-pager'] {
  justify-content: flex-start !important;
}

@media only screen and (max-width: 800px) and (max-height: 800px) {
  .responsiveContainer {
    display: block;
    margin-bottom: 20px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  #root {
    height: auto;
  }
  #appContainer {
    overflow-x: hidden;
  }
  #pageContainer {
    height: auto;
    min-height: 100vh;
  }
  .row {
    flex-direction: column;
  }
  .grid-sm-3 {
    width: 100%;
  }
  footer .row {
    flex-direction: row;
    padding: 0 15px;
  }
  .logos,
  .logos.leadsCouncil {
    margin-bottom: 15px;
    max-width: 75%;
    margin: 0 auto;
    height: auto;
  }
  .logos.leadsCouncil {
    margin-bottom: 0;
  }
}

p {
  margin: 0;
}

@media only screen and (max-height: 600px) {
  #pageContainer {
    height: 100%;
    box-shadow: none;
  }

  footer {
    margin-top: 0;
    padding-top: 3em;
  }
}
